<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="id"
								:search="search"
								:options.sync="options"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" class="ps-4 pt-6 pr-4"></v-text-field>
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.image_url`]="{ item }">
								<v-img class="ma-2" :src="item.image_url" max-height="72" max-width="72" />
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
			loading: false,
			search: '',
			options: {
				sortDesc: [true],
				sortBy: [`creation_date`],
			},
			headers: [
				{ text: ``, value: `image_url`, sortable: false },
				{ text: `Title`, value: `title` },
				{ text: `Message`, value: `body_text` },
				{ text: `Created`, value: `creation_date`, width: `190px` },
			],
			totalItems: 0,
			items: [],
		}),
		methods: {
			refreshContent () {
				this.loading = true
				this.$httpInt.get(`/v2/app/messages`, { params: this.$paginationParams(this, { type: `direct` }) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		name: `MessagesDirectMessages`,
		watch: {
			search: {
				handler (value) {
					if (value && value.length < 4) return
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			options: {
				handler () {
					this.refreshContent()
				},
				deep: true,
			},
		},
	}
</script>
